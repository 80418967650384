


export class BrowserAppEvaluationToolInfo {

  public version = '2024.9.4.1';

  constructor() {
  }

}
